//@import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600&family=Palanquin:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import './helper/variables';
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "config/imports";

body {
  /*!rtl:ignore*/
  font-family: 'Poppins', sans-serif;
  /*!rtl:raw:
  font-family: 'Tajawal', sans-serif;
  */
  letter-spacing: 0.05em;
  color: #333;
  padding-right: 0 !important;
}

@mixin rotate($angle) {
  -moz-transform: rotate($angle);
  -ms-transform: rotate($angle);
  -webkit-transform: rotate($angle);
  transform: rotate($angle);
}

@mixin arrow($arrowSize: 20px, $arrowBorderSize: 3px, $arrowBarWidth: 4px, $arrowBarHeight: 25px, $bottom: 0px) {
  display: inline-block;
  height: $arrowSize;
  position: relative;
  width: $arrowSize;

  &::after {
    content: '' !important;
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
    border-style: solid;
    border-width: 0 0 $arrowSize $arrowSize;
    height: 0;
    width: 0;
  }

  &::before {
    bottom: $bottom;
    content: '' !important;
    height: $arrowBarHeight;
    position: absolute;
    right: 0;
    transform-origin: bottom;
    width: $arrowBarWidth;
    @include rotate(-45deg);
  }

  // States
  &.is-top {
    @include rotate(225deg);
  }

  &.is-right {
    @include rotate(315deg);
  }

  &.is-bottom {
    @include rotate(45deg);
  }

  &.is-left {
    @include rotate(135deg);
  }
}

@mixin arrowColor($color) {

  &,
  .arrow1 {
    &::after {
      border-color: transparent;
      border-bottom-color: $color;
    }

    &::before {
      background-color: $color;
    }
  }
}

.arrow1 {
  margin-left: 25px;
  @include arrow(8px, 2px, 2px, 22px, 1px);
  @include arrowColor($primary);
  transition: 0.3s;
}

.arrow2 {
  margin-left: 30px;
  @include arrow(9px, 2px, 3px, 32px, 2px);
  @include arrowColor($primary);
}

.long-arrow {
  @include arrow(16px, 2px, 5px, 120px, 3px);
  @include arrowColor($primary);

  &.is-right {
    margin-left: 120px;
  }

  &.is-left {
    margin-right: 120px;
  }

  transition: 0.3s;
}

#header {
  .header-body {
    .btn-xl {
      border: 1px solid $secondary;

      &:hover {
        color: $secondary;
        background-color: $primary;

        .arrow1 {
          margin-left: 17px;
          margin-right: 8px;
          @include arrowColor($secondary);
        }
      }
    }
  }
}

section.newsletter-section {
  background-color: $primary;

  h3 {
    color: #fff;
  }

  input {
    border: none;
    border-radius: 0;
  }
}

.flash-news {
  min-width: 135px;
}

.btn-line {
  display: block;
  position: relative;
  z-index: 1;
  border: none;

  &:before {
    opacity: 0;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: $secondary;
    border-radius: 4px;
    transition: all .4s;
    z-index: -1;
  }

  &:after {
    opacity: 1;
    content: "";
    position: absolute;
    bottom: -6px;
    right: 5%;
    width: 90%;
    height: 1px;
    background-color: $primary;
    transition: all 0.4s;
    z-index: 1011;
  }

  &:hover {
    color: $primary;

    &:before {
      opacity: 1;
      width: 100%;
    }

    &:after {
      width: 0;
      transition: all 0.3s;
      z-index: 1011;
    }
  }
}

.btn-secondary-animated {
  &:before {
    opacity: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $primary !important;
    border-radius: 4px;
    transition: all .15s;
    z-index: -1;
  }

  &:hover,
  &:focus,
  &:active {
    color: $secondary !important;

    &:before {
      opacity: 1;
      height: 100%;
    }

    .arrow2 {
      @include arrowColor($secondary);
    }
  }
}

[class*=fluid-col-] .fluid-col {
  position: absolute;
  right: 0;
  left: unset;
}

.pagination {

  .fa-angle-left,
  .fa-angle-right {
    /*!rtl:raw:
    transform: scaleX(-1);
    */
  }
}

.side-fixed {
  position: fixed;
  right: 5vh;
  bottom: 10%;
  z-index: 1000;

  .open {
    display: flex;
    flex-direction: column-reverse;

    >.fa-comment-dots {
      background: $secondary;
      color: $primary;
      height: 48px;
      width: 47px;
      border: 0;
      border-radius: 4px;
      font-size: 1.5rem;
      padding: .3rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 3;
    }

    ul {
      list-style: none;
      padding: 0;
      position: relative;
      visibility: hidden;
      display: block;

      li {
        position: absolute;
        padding: .3rem 0;
        width: auto;
        white-space: nowrap;
        top: -10px;
        transition: .5s all ease-in-out;

        a,
        button {
          display: inline-flex;
          align-items: center;
          color: #fff;

          i {
            background: $secondary;
            color: $primary;
            width: 48px;
            height: 47px;
            border: 0;
            border-radius: 4px;
            font-size: 1.4rem;
            padding: .3rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .side-item {
            display: inline-flex;
            align-items: center;
            background: #37404d;
            border-radius: 2px;
            padding: .2rem .5rem;
            font-size: 12px;
            height: 25px;
          }
        }

        button {
          padding: 0;
          border: 0;
          background: 0 0;
        }
      }
    }

    &:hover {
      ul {
        visibility: visible;

        li {
          &:nth-child(1) {
            top: -45px;
          }

          &:nth-child(2) {
            top: -96px;
          }

          &:nth-child(3) {
            top: -147px;
          }

          &:nth-child(4) {
            top: -198px;
          }

          &:nth-child(5) {
            top: -249px;
          }
        }
      }
    }
  }
}

/* purgecss start ignore */
.popover {
  .popover-arrow {
    display: none;
  }

  .popover-body {
    padding: .4em 1em;
    background-color: #333;
    color: #fff;
  }
}

/* purgecss end ignore */
