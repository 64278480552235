/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger.is-active:hover {
  opacity: 1;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #0E2D52;
}

.hamburger-box {
  width: 34px;
  height: 19px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 34px;
  height: 3px;
  background-color: #0E2D52;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

/*
 * 3DX
 */
.hamburger--3dx .hamburger-box {
  perspective: 68px;
}
.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}
.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DX Reverse
 */
.hamburger--3dx-r .hamburger-box {
  perspective: 68px;
}
.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DY
 */
.hamburger--3dy .hamburger-box {
  perspective: 68px;
}
.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}
.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DY Reverse
 */
.hamburger--3dy-r .hamburger-box {
  perspective: 68px;
}
.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DXY
 */
.hamburger--3dxy .hamburger-box {
  perspective: 68px;
}
.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}
.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DXY Reverse
 */
.hamburger--3dxy-r .hamburger-box {
  perspective: 68px;
}
.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * Arrow
 */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-6.8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-6.8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Arrow Right
 */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(6.8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(6.8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Alt
 */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-6.8px, -8.5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-6.8px, 8.5px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Alt Right
 */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(6.8px, -8.5px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(6.8px, 8.5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Boring
 */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}
.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}
.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Collapse Reverse
 */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Elastic
 */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before {
  top: 8px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner::after {
  top: 16px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
 * Elastic Reverse
 */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r .hamburger-inner::before {
  top: 8px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic-r .hamburger-inner::after {
  top: 16px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
 * Emphatic
 */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 8px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -68px;
  top: -68px;
  transform: translate3d(68px, 68px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -68px;
  top: -68px;
  transform: translate3d(-68px, 68px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Emphatic Reverse
 */
.hamburger--emphatic-r {
  overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::after {
  top: 8px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -68px;
  top: 68px;
  transform: translate3d(68px, -68px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -68px;
  top: 68px;
  transform: translate3d(-68px, -68px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Minus
 */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}
.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 16px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-4.8571428571px, -5px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

/*
 * Slider Reverse
 */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider-r .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider-r .hamburger-inner::after {
  top: 16px;
}
.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(4.8571428571px, -5px, 0);
  opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spin Reverse
 */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spring
 */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 8px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

/*
 * Spring Reverse
 */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}
.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand
 */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand Reverse
 */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Vortex
 */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
 * Vortex Reverse
 */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"), url(https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600&family=Palanquin:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700) format("woff2");
}
body {
  /*!rtl:ignore*/
  font-family: "Poppins", sans-serif;
  /*!rtl:raw:
  font-family: 'Tajawal', sans-serif;
  */
  letter-spacing: 0.05em;
  color: #333;
  padding-right: 0 !important;
}

.arrow1 {
  margin-left: 25px;
  display: inline-block;
  height: 8px;
  position: relative;
  width: 8px;
  transition: 0.3s;
}
.arrow1::after {
  content: "" !important;
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
  border-style: solid;
  border-width: 0 0 8px 8px;
  height: 0;
  width: 0;
}
.arrow1::before {
  bottom: 1px;
  content: "" !important;
  height: 22px;
  position: absolute;
  right: 0;
  transform-origin: bottom;
  width: 2px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.arrow1.is-top {
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.arrow1.is-right {
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.arrow1.is-bottom {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.arrow1.is-left {
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.arrow1::after,
.arrow1 .arrow1::after {
  border-color: transparent;
  border-bottom-color: #0E2D52;
}
.arrow1::before,
.arrow1 .arrow1::before {
  background-color: #0E2D52;
}

.arrow2 {
  margin-left: 30px;
  display: inline-block;
  height: 9px;
  position: relative;
  width: 9px;
}
.arrow2::after {
  content: "" !important;
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
  border-style: solid;
  border-width: 0 0 9px 9px;
  height: 0;
  width: 0;
}
.arrow2::before {
  bottom: 2px;
  content: "" !important;
  height: 32px;
  position: absolute;
  right: 0;
  transform-origin: bottom;
  width: 3px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.arrow2.is-top {
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.arrow2.is-right {
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.arrow2.is-bottom {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.arrow2.is-left {
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.arrow2::after,
.arrow2 .arrow1::after {
  border-color: transparent;
  border-bottom-color: #0E2D52;
}
.arrow2::before,
.arrow2 .arrow1::before {
  background-color: #0E2D52;
}

.long-arrow {
  display: inline-block;
  height: 16px;
  position: relative;
  width: 16px;
  transition: 0.3s;
}
.long-arrow::after {
  content: "" !important;
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
  border-style: solid;
  border-width: 0 0 16px 16px;
  height: 0;
  width: 0;
}
.long-arrow::before {
  bottom: 3px;
  content: "" !important;
  height: 120px;
  position: absolute;
  right: 0;
  transform-origin: bottom;
  width: 5px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.long-arrow.is-top {
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.long-arrow.is-right {
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.long-arrow.is-bottom {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.long-arrow.is-left {
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.long-arrow::after,
.long-arrow .arrow1::after {
  border-color: transparent;
  border-bottom-color: #0E2D52;
}
.long-arrow::before,
.long-arrow .arrow1::before {
  background-color: #0E2D52;
}
.long-arrow.is-right {
  margin-left: 120px;
}
.long-arrow.is-left {
  margin-right: 120px;
}

#header .header-body .btn-xl {
  border: 1px solid #FCAC2F;
}
#header .header-body .btn-xl:hover {
  color: #FCAC2F;
  background-color: #0E2D52;
}
#header .header-body .btn-xl:hover .arrow1 {
  margin-left: 17px;
  margin-right: 8px;
}
#header .header-body .btn-xl:hover .arrow1::after,
#header .header-body .btn-xl:hover .arrow1 .arrow1::after {
  border-color: transparent;
  border-bottom-color: #FCAC2F;
}
#header .header-body .btn-xl:hover .arrow1::before,
#header .header-body .btn-xl:hover .arrow1 .arrow1::before {
  background-color: #FCAC2F;
}

section.newsletter-section {
  background-color: #0E2D52;
}
section.newsletter-section h3 {
  color: #fff;
}
section.newsletter-section input {
  border: none;
  border-radius: 0;
}

.flash-news {
  min-width: 135px;
}

.btn-line {
  display: block;
  position: relative;
  z-index: 1;
  border: none;
}
.btn-line:before {
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: #FCAC2F;
  border-radius: 4px;
  transition: all 0.4s;
  z-index: -1;
}
.btn-line:after {
  opacity: 1;
  content: "";
  position: absolute;
  bottom: -6px;
  right: 5%;
  width: 90%;
  height: 1px;
  background-color: #0E2D52;
  transition: all 0.4s;
  z-index: 1011;
}
.btn-line:hover {
  color: #0E2D52;
}
.btn-line:hover:before {
  opacity: 1;
  width: 100%;
}
.btn-line:hover:after {
  width: 0;
  transition: all 0.3s;
  z-index: 1011;
}

.btn-secondary-animated:before {
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #0E2D52 !important;
  border-radius: 4px;
  transition: all 0.15s;
  z-index: -1;
}
.btn-secondary-animated:hover, .btn-secondary-animated:focus, .btn-secondary-animated:active {
  color: #FCAC2F !important;
}
.btn-secondary-animated:hover:before, .btn-secondary-animated:focus:before, .btn-secondary-animated:active:before {
  opacity: 1;
  height: 100%;
}
.btn-secondary-animated:hover .arrow2::after,
.btn-secondary-animated:hover .arrow2 .arrow1::after, .btn-secondary-animated:focus .arrow2::after,
.btn-secondary-animated:focus .arrow2 .arrow1::after, .btn-secondary-animated:active .arrow2::after,
.btn-secondary-animated:active .arrow2 .arrow1::after {
  border-color: transparent;
  border-bottom-color: #FCAC2F;
}
.btn-secondary-animated:hover .arrow2::before,
.btn-secondary-animated:hover .arrow2 .arrow1::before, .btn-secondary-animated:focus .arrow2::before,
.btn-secondary-animated:focus .arrow2 .arrow1::before, .btn-secondary-animated:active .arrow2::before,
.btn-secondary-animated:active .arrow2 .arrow1::before {
  background-color: #FCAC2F;
}

[class*=fluid-col-] .fluid-col {
  position: absolute;
  right: 0;
  left: unset;
}

.pagination .fa-angle-left,
.pagination .fa-angle-right {
  /*!rtl:raw:
  transform: scaleX(-1);
  */
}

.side-fixed {
  position: fixed;
  right: 5vh;
  bottom: 10%;
  z-index: 1000;
}
.side-fixed .open {
  display: flex;
  flex-direction: column-reverse;
}
.side-fixed .open > .fa-comment-dots {
  background: #FCAC2F;
  color: #0E2D52;
  height: 48px;
  width: 47px;
  border: 0;
  border-radius: 4px;
  font-size: 1.5rem;
  padding: 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
}
.side-fixed .open ul {
  list-style: none;
  padding: 0;
  position: relative;
  visibility: hidden;
  display: block;
}
.side-fixed .open ul li {
  position: absolute;
  padding: 0.3rem 0;
  width: auto;
  white-space: nowrap;
  top: -10px;
  transition: 0.5s all ease-in-out;
}
.side-fixed .open ul li a,
.side-fixed .open ul li button {
  display: inline-flex;
  align-items: center;
  color: #fff;
}
.side-fixed .open ul li a i,
.side-fixed .open ul li button i {
  background: #FCAC2F;
  color: #0E2D52;
  width: 48px;
  height: 47px;
  border: 0;
  border-radius: 4px;
  font-size: 1.4rem;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.side-fixed .open ul li a .side-item,
.side-fixed .open ul li button .side-item {
  display: inline-flex;
  align-items: center;
  background: #37404d;
  border-radius: 2px;
  padding: 0.2rem 0.5rem;
  font-size: 12px;
  height: 25px;
}
.side-fixed .open ul li button {
  padding: 0;
  border: 0;
  background: 0 0;
}
.side-fixed .open:hover ul {
  visibility: visible;
}
.side-fixed .open:hover ul li:nth-child(1) {
  top: -45px;
}
.side-fixed .open:hover ul li:nth-child(2) {
  top: -96px;
}
.side-fixed .open:hover ul li:nth-child(3) {
  top: -147px;
}
.side-fixed .open:hover ul li:nth-child(4) {
  top: -198px;
}
.side-fixed .open:hover ul li:nth-child(5) {
  top: -249px;
}

/* purgecss start ignore */
.popover .popover-arrow {
  display: none;
}
.popover .popover-body {
  padding: 0.4em 1em;
  background-color: #333;
  color: #fff;
}

/* purgecss end ignore */